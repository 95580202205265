import { Method } from "axios"
import { IAuthConfig } from "./types"

import { ISimplifiedApiError, fetchWithConfig } from "../../utils/rest"
import { IPublicRuntimeConfig } from "../../utils/runtimeConfig"

export const defaultAuthError: ISimplifiedApiError = {
  message: "Something went wrong",
  code: 500,
  reason: "system",
  isError: true,
}

export async function apiGetAuthConfig(
  authConfig: IAuthConfig
): Promise<{
  url: string
  method: Method
  data?: any
  withAuthHeader: boolean
  headers?: Record<string, string>
}> {
  const { payload, method, path, withAuthHeader = true, headers } = authConfig

  return {
    url: path,
    method: method || "POST",
    withAuthHeader,
    ...(payload && { data: payload }),
    ...(headers && { headers }),
  }
}
export async function apiAuthRequest<T>(
  authConfig: IAuthConfig & { getRuntimeConfig: () => IPublicRuntimeConfig }
): Promise<T | ISimplifiedApiError> {
  const config = await apiGetAuthConfig(authConfig)
  try {
    return await fetchWithConfig(config, authConfig.getRuntimeConfig, "advice")
  } catch (e) {
    console.error(e)

    return defaultAuthError
  }
}
